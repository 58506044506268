<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Đơn vị đối soát(*)">
          <b-form-select
            v-model="bank.wallet_type"
            :options="option.wallet_type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Loại (*)">
          <b-form-select
            v-model="bank.action_type"
            :options="option.action_type"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Mã giao dịch">
          <b-form-input v-model="bank.code"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Mã ngân hàng">
          <b-form-input v-model="bank.bank_code"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Trạng thái ví">
          <b-form-select
            v-model="bank.wallet_status"
            :options="option.wallet_status"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="bank.wallet_type == 'MBBANK'">
        <b-form-group label="Mã MB-9PAY">
          <b-form-input v-model="bank.bank_9pay"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tìm kiếm theo">
          <b-form-select
            v-model="bank.date_type"
            :options="option.date_type"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Từ ngày">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="bank.from"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Đến ngày">
          <b-form-input
            :id="`type-date`"
            :type="`date`"
            v-model="bank.to"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "optionWalleType"],
  mixins: [Common],
  data() {
    return {
      bank: {
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        action_type: null,
        code: null,
        bank_code: null,
        wallet_status: null,
        wallet_type: this.walletType,
        bank_9pay: null,
        respon_code: null,
        date_type: "payment_at",
      },
      option: {
        date_type: [
          { value: "payment_at", text: "Thời gian hoàn thành" },
          { value: "created_at", text: "Thời gian tạo" },
        ],
        action_type: [
          { value: null, text: "ALL" },
          { value: "DEPOSIT", text: "Nạp tiền" },
          { value: "WITHDRAW", text: "Rút tiền" },
        ],
        wallet_status: [
          { value: null, text: "ALL" },
          { value: "-1", text: "FAIL" },
          { value: "0", text: "PENDING" },
          { value: "1", text: "SUCCESS" },
          { value: "-2", text: "CANCELED" },
          { value: "2", text: "REVERSED" },
        ],
        request_status: [
          { value: null, text: "ALL" },
          { value: "-1", text: "FAIL" },
          { value: "0", text: "PENDING" },
          { value: "1", text: "SUCCESS" },
        ],
        wallet_type: this.optionWalleType,
      },
    };
  },
  methods: {},
  watch: {
    "bank.wallet_type"() {
      this.$emit("updateWalletType", this.bank.wallet_type);
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
