<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tool đối soát</h6>
        </template>
        <div
          v-if="
            formInput.wallet_type == 'NAPAS' || formInput.wallet_type == null
          "
        >
          <b-row>
            <b-col>
              <b-form-group label="Đơn vị đối soát(*)">
                <b-form-select
                  v-model="formInput.wallet_type"
                  :options="options.wallet_type"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Tùy chọn thời gian(*)">
                <b-form-select
                  v-model="formInput.date_type"
                  :options="options.date_type"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày bắt đầu(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.startDate"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày kết thúc(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.endDate"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Code">
                <b-form-input
                  :id="`Code`"
                  v-model="formInput.code"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Payment No">
                <b-form-input
                  :id="`paymentNo`"
                  v-model="formInput.paymentNo"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Napas ID">
                <b-form-input
                  :id="`napasId`"
                  v-model="formInput.napasId"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Từ khóa">
                <b-form-input v-model="formInput.key_word"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="
            formInput.wallet_type != null &&
            options.lis_bank.includes(formInput.wallet_type)
          "
        >
          <FormBank
            ref="formBank"
            :walletType="formInput.wallet_type"
            :optionWalleType="options.wallet_type"
            @updateWalletType="updateWalletType"
          ></FormBank>
          <br />
        </div>

        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="searchDataWallet(1)"
              >Tìm kiếm</b-button
            >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="$bvModal.show('export-desc')"
              >Xuất excel</b-button
            >&nbsp;
          </b-col>
        </b-row>
        <div v-if="formInput.wallet_type != 'REVERSES_PROFILE'">
          <b-row>
            <b-col> </b-col>
            <b-col> </b-col>
            <b-col v-if="formInput.wallet_type == 'TRANSACTION' && this.totalAmount == null">
              <b @click="getTotalTransaction()"><i class="menu-icon flaticon-eye" > </i>Xem tổng tiền</b>
            </b-col>
            <b-col v-if="this.totalAmount != null">
              <b>Tổng tiền: </b> {{ numberFormat(this.totalAmount) }} VNĐ
            </b-col>

          </b-row>
          <b-row v-if="formInput.wallet_type != 'TRANSACTION'">
            <b-col> </b-col>
            <b-col> </b-col>
            <b-col v-if="this.totalAmount != null">
              <b>Tổng số GD: </b> {{ this.total }} giao dịch
            </b-col>
          </b-row>
        </div>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Danh sách" active>
              <b-table
                :items="items"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top
              >
                <template #cell(index)="data">
                  {{ (view.currentPage - 1) * 25 + (data.index + 1) }}
                </template>
                <template #cell(price)="data">
                  {{ numberFormat(data.item.price) }}
                </template>
                <template #cell(voucher)="data">
                  {{ numberFormat(data.item.voucher) }}
                </template>
                <template #cell(fee)="data">
                  {{ numberFormat(data.item.fee) }}
                </template>
                <template #cell(amount)="data">
                  {{ numberFormat(data.item.amount) }}
                </template>
                <template #cell(remain_original_amount)="data">
                  {{ numberFormat(data.item.remain_original_amount) }}
                </template>
                <template #cell(bank_amount)="data">
                  {{ numberFormat(data.item.bank_amount) }}
                </template>
                <template #cell(total)="data">
                  {{ numberFormat(data.item.total) }}
                </template>
                <template #cell(fee_received)="data">
                  {{ numberFormat(data.item.fee_received) }}
                </template>
                <template #cell(fee_expense_direct)="data">
                  {{ numberFormat(data.item.fee_expense_direct) }}
                </template>
                <template #cell(fee_expense_percent)="data">
                  {{ numberFormat(data.item.fee_expense_percent) }}
                </template>
                <template #cell(created_at)="data">
                  <span>
                    {{ formatDate(data.item.created_at) }}
                  </span>
                </template>
                <template #cell(updated_at)="data">
                  <span>
                    {{ formatDate(data.item.updated_at) }}
                  </span>
                </template>
              </b-table>

              <div class="mt-3">
                <b-pagination-nav
                  v-model="view.currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="view.paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </b-card-group>
    <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
      <br />
      <div class="d-block text-center">
        <b-form-textarea
          placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
          v-model="formInput.exportDesc"
          rows="3"
        ></b-form-textarea>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
        <b-button size="sm" variant="success" @click="exportData()">
          Xuất
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import FormBank from "./FormBank";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {
    FormBank,
  },
  data() {
    return {
      items: [],
      total: 0,
      totalAmount: 0,
      itemsFile: [],
      fieldsFile: [
        { id: "Id" },
        { export_type: "Loại dữ liệu" },
        { storage_file: "Download" },
        { status: "Trạng thái" },
        { request_user: "Người tạo" },
        { created_at: "Thời gian tạo" },
      ],
      fields: [],
      formInput: {
        wallet_type: 'NAPAS',
        status: null,
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay(),
        napasId: null,
        paymentNo: null,
        code: null,
        exportDesc: null,
        //Request Thanh toán hóa đơn
        start_update: this.getYesterday(),
        end_update: this.getCurrentDay(),
        start_created: null,
        end_created: null,
        tran_code: null,
        order_code: null,
        payment_id: null,
        provider: null,
        supplier_order: null,
        //Request Ví - Cổng
        tran_core_status: null,
        key_word: null,
        phone_number: null,
        date_type: "payment_at",
        profile_code: null,
        sub_profile_code: null,
        action_code: null,
        reverse_type: null,
        to_account: null,
        from_account: null,
      },
      options: {
        date_type: [
          { value: "payment_at", text: "Thời gian hoàn thành" },
          { value: "created_at", text: "Thời gian tạo" },
        ],
        wallet_type: [
          { value: "NAPAS", text: "NAPAS" },
          { value: "BIDV", text: "BIDV" },
          { value: "MBBANK", text: "MBBANK" },
          { value: "CTG", text: "VIETTINBANK" },
          { value: "VPB", text: "VPBANK" },
          { value: "TCB", text: "TECHCOMBANK" },
          { value: "STBBANK", text: "SACOMBANK" },
          { value: "MSB", text: "MSB" },
          { value: "VCCB", text: "Bản Việt" },
          { value: "ACB", text: "ACB" },
          { value: "VCB", text: "VCB" },
        ],
        lis_bank: [
          "BIDV",
          "MBBANK",
          "STBBANK",
          "TCB",
          "CTG",
          "VPB",
          "MSB",
          "VCCB",
          "ACB",
          "VCB"
        ],
        status: [
          { value: null, text: "ALL" },
          { value: "-1", text: "FAIL" },
          { value: "0", text: "PENDING" },
          { value: "1", text: "SUCCESS" },
          { value: "2", text: "REFUND" },
        ],
        provider: [
          { value: null, text: "ALL" },
          { value: "PAYOO", text: "PAYOO" },
          { value: "IMEDIA", text: "IMEDIA" },
        ],
        core_status: [
          { value: null, text: "ALL" },
          { value: "FAIL", text: "FAIL" },
          { value: "PENDING", text: "PENDING" },
          { value: "SUCCESS", text: "SUCCESS" },
          { value: "CANCEL", text: "CANCEL" },
          { value: "REVERSED", text: "REVERSED" },
        ],
      },
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
      download: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tool đối soát ví", route: "checkWallet" },
    ]);
  },
  methods: {
    getTotalTransaction(){
      if (this.formInput.wallet_type == null) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }
      if (this.formInput.startDate > this.formInput.endDate) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      let params = this.formInput;
      if (this.formInput.status != null && this.formInput.status != "") {
        params.status = this.formInput.status;
      }
      if (this.formInput.napasId != null && this.formInput.napasId != "") {
        params.napas_id = this.formInput.napasId;
      }
      if (this.formInput.code != null && this.formInput.code != "") {
        params.code = this.formInput.code;
      }
      if (this.formInput.paymentNo != null && this.formInput.paymentNo != "") {
        params.payment_no = this.formInput.paymentNo;
      }

      this.$bus.$emit("show-loading", true);
      CmsRepository.totalReconcileWallet(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
            } else {
              this.totalAmount = response.data.data.data.total;
              this.notifyAlert("success", "Lấy dữ liệu thành công");
            }
            this.$bus.$emit("show-loading", false);
          })
          .catch(() => {

            this.notifyAlert("warn", "Có lỗi xảy ra");
          });
    },
    searchDataWallet(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        this.$router.replace({});
        this.view.currentPage = 1;
      }
      if (this.formInput.wallet_type == null) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }
      if (this.formInput.startDate > this.formInput.endDate) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      let params = {
        wallet_type: this.formInput.wallet_type,
        from: this.formInput.startDate,
        to: this.formInput.endDate,
        page: numberPage,
        date_type: this.formInput.date_type,
      };
      if (this.formInput.status != null && this.formInput.status != "") {
        params.status = this.formInput.status;
      }
      if (this.formInput.napasId != null && this.formInput.napasId != "") {
        params.napas_id = this.formInput.napasId;
      }
      if (this.formInput.code != null && this.formInput.code != "") {
        params.code = this.formInput.code;
      }
      if (this.formInput.paymentNo != null && this.formInput.paymentNo != "") {
        params.payment_no = this.formInput.paymentNo;
      }
      // Request Thanh toan hoa don
      if (this.options.lis_bank.includes(this.formInput.wallet_type)) {
        this.formInput.wallet_type = this.$refs.formBank.bank.wallet_type;
        let list = { ...this.$refs.formBank.bank };
        params = this.removeValidateNull(list);
      } else if (this.formInput.wallet_type != "NAPAS") {
        let list = { ...this.formInput };
        list.tran_core_status = null;
        params = this.convert(list);
      }

      params.page = this.view.currentPage ?? 1;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listShowReconcileWallet(params)
        .then((response) => {
          this.items = [];
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.fields = response.data.data.fields;
            this.totalAmount = response.data.data.totalAmount;
            this.total = response.data.data.total;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage =
              response.data.data.lastPage ?? response.data.data.last_page ?? 1;
            this.view.paginate.currentPage =
              response.data.data.currentPage ??
              response.data.data.current_page ??
              1;
            this.notifyAlert("success", "Lấy dữ liệu thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xẩy ra");
        });
    },
    exportData() {
      if (this.formInput.wallet_type == null) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }
      if (this.formInput.start_update > this.formInput.end_update) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      if (this.formInput.startDate > this.formInput.endDate) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      let params = {
        wallet_type: this.formInput.wallet_type,
        from: this.formInput.startDate,
        to: this.formInput.endDate,
        date_type: this.formInput.date_type,
      };
      if (this.formInput.status != null && this.formInput.status != "") {
        params.status = this.formInput.status;
      }
      if (this.formInput.napasId != null && this.formInput.napasId != "") {
        params.napas_id = this.formInput.napasId;
      }
      if (this.formInput.code != null && this.formInput.code != "") {
        params.code = this.formInput.code;
      }
      if (this.formInput.paymentNo != null && this.formInput.paymentNo != "") {
        params.payment_no = this.formInput.paymentNo;
      }

      if (this.options.lis_bank.includes(this.formInput.wallet_type)) {
        this.formInput.wallet_type = this.$refs.formBank.bank.wallet_type;
        let list = { ...this.$refs.formBank.bank };
        params = this.removeValidateNull(list);
      } else if (this.formInput.wallet_type != "NAPAS") {
        let list = { ...this.formInput };
        params = this.convert(list);
      }

      if (
        this.formInput.exportDesc != null &&
        this.formInput.exportDesc != ""
      ) {
        params.export_desc = this.formInput.exportDesc;
      }
      // params.page = this.view.currentPage ?? 1;
      this.$bvModal.hide("export-desc");
      this.$bus.$emit("show-loading", true);
      params = this.convert(params)

      CmsRepository.exportFileReconcileWallet(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert(
              "success",
              "Tạo tiến trình thành công. Vui lòng chờ"
            );
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xẩy ra");
        });
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    updateWalletType(e) {
      this.formInput.wallet_type = e;
    },
  },
  created() {
    // this.listExportFile();
    // this.searchData();
  },
  watch: {
    "view.currentPage"() {
      this.searchDataWallet();
    },
  },
};
</script>
